/* eslint-disable consistent-return */
import React, { useReducer, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import WingKey from './WingKey';
import RenderAnimation from '../RenderAnimation';
import s from './Loaders.module.scss';
import timeturner from '../../animations/timeturner_outerring.json';
import paintbrush from '../../animations/paintbrush.json';

import LoadersReducer, { SET_PROGRESS_BAR_LENGTH } from './reducer';

import loaderTypes from './loaderTypes';

const propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

const INITIAL_STATE = {
  isLoading: true,
  progressBarLength: 1000,
};

const renderLoader = (type, progressBarLength) => {
  if (type === loaderTypes.timeturner) {
    return (
      <RenderAnimation
        ariaLabel="Loading indicator"
        animation="timeturner"
        animationData={timeturner}
      />
    );
  }
  if (type === loaderTypes.paintbrush) {
    return (
      <RenderAnimation
        ariaLabel="Loading indicator"
        animation="paintbrush"
        animationData={paintbrush}
        className={s.paintbrushLoader}
      />
    );
  }

  return <WingKey s={s} progressBarLength={progressBarLength} />;
};

const useSetProgressBarLength = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    /* istanbul ignore next */
    const increment = () => {
      savedCallback.current();
    };

    const timer = setInterval(increment, delay);
    return () => clearInterval(timer);
  }, [delay]);
};

const Loaders = ({ type = loaderTypes.wingkey, title = '', subtitle = '', ...props }) => {
  const [state, dispatch] = useReducer(LoadersReducer, INITIAL_STATE);
  const { progressBarLength } = state;

  useSetProgressBarLength(() => {
    dispatch({ type: SET_PROGRESS_BAR_LENGTH, payload: 5 });
  }, 10);

  return (
    <div className={s.loader} {...props}>
      <div className={s.loaderContainer}>
        {renderLoader(type, progressBarLength)}
        {title.length > 0 && (
          <div className={s.messageContainer}>
            <span>{title}</span>
            <br />
            {subtitle && <span>{subtitle}</span>}
          </div>
        )}
      </div>
    </div>
  );
};

Loaders.propTypes = propTypes;
export default Loaders;
