import React, { createContext, useContext } from 'react';

export const NavContext = createContext();

export const useNavContext = () => useContext(NavContext);

// eslint-disable-next-line react/prop-types
const NavContextProvider = ({ value, children }) => {
  return <NavContext.Provider value={value}>{children}</NavContext.Provider>;
};

export default NavContextProvider;
