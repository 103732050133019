import gql from 'graphql-tag';

export const REGISTER_GET_COUNTRY = gql`
  query {
    register {
      country
    }
  }
`;

export const REGISTER = gql`
  query registerQuery($email: String!) {
    register(email: $email) {
      isPottermore
      isWW
    }
  }
`;

export const CHECK_ISUNDERAGE = gql`
  query($birthdate: String!) {
    underage(birthdate: $birthdate) {
      isUnderage
    }
  }
`;

export const DELETE_ACCOUNT = gql`
  mutation deleteAccount {
    deleteAccount {
      id
    }
  }
`;

export const RELATED_QUERY = gql`
  query relatedContent($externalId: String) {
    relatedContent(externalId: $externalId) {
      results {
        id
        body
        contentTypeId
      }
    }
  }
`;

export const REGION = gql`
  {
    register {
      country
    }
  }
`;

export const STORE_URL = gql`
  query shopifyUrl($path: String!, $store: String) {
    storeUrl(path: $path, store: $store) {
      url
    }
  }
`;

export const GET_SIGNED_URL = gql`
  query getSignedUrl($url: String!) {
    getSignedUrl(url: $url) {
      url
    }
  }
`;

export const COUNTRY_PRODUCTS_QUERY = gql`
  query {
    country {
      allowed
      code
    }
  }
`;

export const LINKED_PROFILES_QUERY = gql`
  query {
    me {
      linkedProfiles {
        groupId
        name
      }
    }
  }
`;

export const PROFILE_QUERY = gql`
  query {
    me {
      id
      givenName
      familyName
      email
      emailVerified
      hogwartsHouse
      underage
      subscription
      birthdate
      premiumDate
      registeredOn
      marketingOptInWW
      sortingHouseCeremonyDate
      privacy {
        doNotSellOrShare
      }
      subscriptions(status: "active") {
        type
        status
        subType
        startDate
        endDate
        autoRenew
        renewCount
      }
      country
      wand {
        core
        flexibility
        wood
        length
      }
      patronusName
      patronusFileName
      favorites {
        character
        spellOrPotion
        placeOrTransport
        beastOrBeing
        quidditchPosition
        objectOrFood
      }
      avatar {
        avatarId
        url
        background {
          contentfulId
          variantContentfulId
        }
        pet {
          contentfulId
          variantContentfulId
        }
        body {
          contentfulId
          variantContentfulId
        }
        clothing {
          contentfulId
          variantContentfulId
        }
        frontAccessory {
          contentfulId
          variantContentfulId
        }
        backAccessory {
          contentfulId
          variantContentfulId
        }
        facialShape {
          contentfulId
          variantContentfulId
        }
        eyes {
          contentfulId
          variantContentfulId
        }
        nose {
          contentfulId
          variantContentfulId
        }
        mouth {
          contentfulId
          variantContentfulId
        }
        eyeDecorator {
          contentfulId
          variantContentfulId
        }
        facialDecorator {
          contentfulId
          variantContentfulId
        }
        facialHair {
          contentfulId
          variantContentfulId
        }
        hair {
          contentfulId
          variantContentfulId
        }
        hand {
          contentfulId
          variantContentfulId
        }
        frame {
          contentfulId
          variantContentfulId
        }
      }
    }
  }
`;

export const UNDERAGE_QUERY = gql`
  query {
    me {
      underage
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation updateProfile(
    $givenName: String
    $familyName: String
    $marketingOptInWW: Boolean
    $locale: String
  ) {
    updateProfile(
      givenName: $givenName
      familyName: $familyName
      marketingOptInWW: $marketingOptInWW
      locale: $locale
    ) {
      id
      givenName
      familyName
      marketingOptInWW
      locale
    }
  }
`;

export const CONTENT_QUERY = gql`
  query ContentQuery(
    $contentTypes: [String!]
    $count: Int
    $offset: Int
    $tags: [String!]
    $excludeTags: [String!]
    $externalId: String
  ) {
    content(
      contentTypes: $contentTypes
      count: $count
      offset: $offset
      tags: $tags
      excludeTags: $excludeTags
      externalId: $externalId
    ) {
      results {
        id
        body
        contentTypeId
      }
    }
  }
`;

export const FETCH_CONTENT_QUERY = gql`
  query ContentQuery(
    $contentTypes: [String!]
    $count: Int
    $offset: Int
    $contentfulIds: [String!]
    $tags: [String!]
    $excludeTags: [String!]
    $excludeIds: [String!]
    $externalId: String
    $externalIds: [String!]
    $sortBy: [ContentSortBy!]
  ) {
    content(
      contentTypes: $contentTypes
      count: $count
      offset: $offset
      contentfulIds: $contentfulIds
      tags: $tags
      excludeTags: $excludeTags
      excludeIds: $excludeIds
      externalId: $externalId
      externalIds: $externalIds
      sortBy: $sortBy
    ) {
      results {
        id
        body
        contentTypeId
      }
    }
  }
`;

export const GET_QUIZ_RESULT = gql`
  query QuizResult($quizEntryId: ID!) {
    quizResult(quizEntryId: $quizEntryId) {
      correct
      total
    }
  }
`;

export const SUBMIT_QUIZ = gql`
  mutation submitQuiz($input: SubmitQuizInput!) {
    submitQuiz(input: $input) {
      quizResult {
        quizEntryId
        duration
        correct
        total
        quizChallengeId
      }
    }
  }
`;

export const GET_QUIZ_STATE_WITH_RESULTS = gql`
  query quizSuperQuery($quizEntryId: ID!) {
    quizResult(quizEntryId: $quizEntryId) {
      quizEntryId
      duration
      correct
      total
    }

    quizState(quizEntryId: $quizEntryId) {
      quizEntryId
      savedOn
      answeredQuestions {
        quizQuestionEntryId
        answers
        duration
      }
    }
  }
`;

export const GET_QUIZ_STATE = gql`
  query QuizState($quizEntryId: ID!) {
    quizState(quizEntryId: $quizEntryId) {
      quizEntryId
      savedOn
      answeredQuestions {
        quizQuestionEntryId
        answers
        duration
      }
    }
  }
`;

export const SAVE_QUIZ_STATE = gql`
  mutation saveQuizState($input: SubmitQuizInput!) {
    saveQuizState(input: $input) {
      saved
    }
  }
`;

export const GET_AGGREGATE_QUIZ_RESULT = gql`
  query AggregateQuizResult($quizEntryId: ID) {
    aggregatedQuizResult(quizEntryId: $quizEntryId) {
      gryffindor {
        correct
        records
        total
      }
      hufflepuff {
        correct
        records
        total
      }
      ravenclaw {
        correct
        records
        total
      }
      slytherin {
        correct
        records
        total
      }
    }
  }
`;

export const CONTENT_HISTORY_EVENT_COUNT = gql`
  query ContentHistoryEventCount(
    $event: EventMetadataInput!
    $filters: AggregateFilterInput!
    $period: AggregatePeriodInput
  ) {
    contentHistoryEventCount(event: $event, filters: $filters, period: $period) {
      count
      total
    }
  }
`;

export const GET_UNLOCKED_INVENTORY = gql`
  query getUnlockedInventory(
    $achievementSourcePlatform: AchievementSourcePlatform
    $achievementContentfulId: String
    $inventoryItemContentfulId: String
    $externalAchievementId: String
    $unlockedContentfulTypes: [String!]
    $count: Int
  ) {
    getUnlockedInventory(
      achievementSourcePlatform: $achievementSourcePlatform
      achievementContentfulId: $achievementContentfulId
      inventoryItemContentfulId: $inventoryItemContentfulId
      externalAchievementId: $externalAchievementId
      unlockedContentfulTypes: $unlockedContentfulTypes
      count: $count
    ) {
      results {
        achievementSourcePlatform
        achievementContentfulId
        unlockedContentfulId
        externalAchievementId
        unlockedContentfulType
        created
        lastModified
      }
      found
    }
  }
`;

export const UNLINK_PROFILE = gql`
  mutation unlinkProfile($groupId: String) {
    unlinkProfile(groupId: $groupId) {
      success
    }
  }
`;

export const SYNC_ACHIEVEMENTS = gql`
  mutation syncAchievements($achievementSourcePlatform: AchievementSourcePlatform) {
    syncAchievements(achievementSourcePlatform: $achievementSourcePlatform) {
      found
    }
  }
`;

export const GET_USER_LINK_CODE = gql`
  mutation {
    linkProfile {
      shortCode
    }
  }
`;

export const SAVE_AVATAR_SELECTIONS = gql`
  mutation saveAvatar($avatar: AvatarInput) {
    saveAvatar(avatar: $avatar) {
      avatarId
    }
  }
`;

export const CREATE_PARENT = gql`
  mutation createParent(
    $email: String!
    $childName: String!
    $childBirthDate: DateType!
    $isMigrate: Boolean
    $locale: String
  ) {
    createParent(
      email: $email
      childName: $childName
      childBirthDate: $childBirthDate
      isMigrate: $isMigrate
      locale: $locale
    ) {
      id
    }
  }
`;

export const UPDATE_PARENT = gql`
  mutation updateParent($parentId: ID!, $childName: String!, $email: String!) {
    updateParent(parentId: $parentId, childName: $childName, email: $email) {
      id
    }
  }
`;

export const GET_PARENT = gql`
  query($id: ID!) {
    getParent(id: $id) {
      id
      email
      invokedAt
      grantedAt
      childId
      childName
      sevenDayConfirmedAt
      twentyEightDayConfirmedAt
      childBirthDate
      isMigrate
    }
    register {
      country
    }
  }
`;

export const REINVOKE_PARENT = gql`
  mutation reinvokeParent($parentId: ID!) {
    reinvokeParent(parentId: $parentId) {
      id
      invokedAt
    }
  }
`;

export const GRANT_CHILD = gql`
  mutation grantChild($parentId: ID!, $childEmail: String!, $isMigrate: Boolean) {
    grantChild(parentId: $parentId, childEmail: $childEmail, isMigrate: $isMigrate) {
      id
      grantedAt
    }
  }
`;

export const DENY_CHILD = gql`
  mutation denyChild($parentId: ID!) {
    denyChild(parentId: $parentId) {
      id
    }
  }
`;

export const LINK_CHILD = gql`
  mutation linkChild($parentId: ID!, $childId: ID!, $childBirthDate: DateType!) {
    linkChild(parentId: $parentId, childId: $childId, childBirthDate: $childBirthDate) {
      id
      childId
      childBirthDate
    }
  }
`;

export const UNLOCK_CODE = gql`
  query unlockCode($externalId: String!) {
    unlockCode(externalId: $externalId) {
      errorCode
      isNewCode
      results {
        id
        body
        contentTypeId
      }
    }
  }
`;

export const POLL_RESULT = gql`
  query pollResult($pollId: ID!) {
    pollResult(pollId: $pollId) {
      id
      pollId
      userId
      pollAnswer
    }
  }
`;

export const AGGREGATED_POLL_RESULT = gql`
  query aggregatedPollResult($pollId: ID!) {
    aggregatedPollResult(pollId: $pollId) {
      id
      answers {
        answer
        count
      }
    }
  }
`;

export const SUBMIT_POLL = gql`
  mutation submitPoll($input: SubmitPollInput!) {
    submitPoll(input: $input) {
      pollId
      pollAnswer
    }
  }
`;
