import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import pkg from '../../../package.json';

const ignoredErrors = [
  'ResizeObserver loop limit exceeded', // PM-15687, https://stackoverflow.com/a/50387233
];

const propTypes = {
  children: PropTypes.node.isRequired,
};

const BugsnagWrapper = ({ children }) => {
  const isMounted = useRef(null);
  if (process.browser && process.env.REACT_APP_BUGSNAG_CLIENT_API_KEY && !isMounted.current) {
    isMounted.current = true;

    /* istanbul ignore next */
    const bugsnagClient = Bugsnag.start({
      apiKey: process.env.REACT_APP_BUGSNAG_CLIENT_API_KEY,
      appType: process.env.REACT_APP_DOCKER === 'TRUE' ? 'docker' : 'lambda',
      appVersion: pkg.version,
      releaseStage: process.env.REACT_APP_ENV,
      plugins: [new BugsnagPluginReact()],
      onError: (event) => {
        // ignore errors by returning false if they are in the ignoredErrors list
        return !ignoredErrors.some((ignoredError) =>
          new RegExp(ignoredError).test(event?.errors?.[0]?.errorMessage),
        );
      },
    });

    const ErrorBoundary = bugsnagClient.getPlugin('react').createErrorBoundary(React);
    return <ErrorBoundary>{children}</ErrorBoundary>;
  }

  return children;
};

BugsnagWrapper.propTypes = propTypes;
export default BugsnagWrapper;
