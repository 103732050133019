/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import s from './Button.module.scss';

const propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['primary', 'secondary', 'text']),
  buttonType: PropTypes.oneOf(['button', 'submit']),
  variant: PropTypes.oneOf([null, 'gryffindor', 'hufflepuff', 'ravenclaw', 'slytherin', 'danger']),
  mode: PropTypes.oneOf(['light', 'dark']),
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onClick: PropTypes.func,
  contentLeft: PropTypes.node,
  contentRight: PropTypes.node,
  m: PropTypes.string,
  mx: PropTypes.string,
  my: PropTypes.string,
  mt: PropTypes.string,
  mr: PropTypes.string,
  mb: PropTypes.string,
  ml: PropTypes.string,
  className: PropTypes.string,
};

const Button = ({
  label,
  type = 'primary',
  buttonType = 'button',
  variant = null,
  mode = 'dark',
  size = 'md',
  disabled = false,
  loading = false,
  fullWidth = false,
  onClick = null,
  contentLeft = null,
  contentRight = null,
  m = null,
  mx = null,
  my = null,
  mt = null,
  mr = null,
  mb = null,
  ml = null,
  className = '',
  ...props
}) => {
  // allows adding custom margin values without need for custom classnames
  const marginStyle = {
    margin: m,
    marginLeft: ml || mx,
    marginRight: mr || mx,
    marginTop: mt || my,
    marginBottom: mb || my,
  };

  const getButtonClass = () => {
    let buttonClass = `${s[type.toLowerCase()]} ${s[size]}`;
    if (variant) buttonClass += ` ${s[variant]}`;
    if (mode === 'light') buttonClass += ` ${s.light}`;
    if (fullWidth) buttonClass += ` ${s.fullWidth}`;
    if (disabled) buttonClass += ` ${s.disabled}`;
    if (loading) buttonClass += ` ${s.loading}`;
    return buttonClass;
  };

  return (
    <button
      className={[getButtonClass(), s.button, className].join(' ')}
      onClick={onClick}
      disabled={disabled || loading}
      // eslint-disable-next-line react/button-has-type
      type={buttonType}
      data-testid="button-v3"
      style={{ ...marginStyle }}
      {...props}
    >
      <span className={s.content}>
        {contentLeft && <span className={s.contentLeft}>{contentLeft}</span>}
        {label}
        {contentRight && <span className={s.contentRight}>{contentRight}</span>}
      </span>
      {loading && <span className={s.spinner} data-testid="loading-spinner" />}
    </button>
  );
};

Button.propTypes = propTypes;
export default Button;
