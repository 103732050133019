import { useEffect } from 'react';
import { NEWS_URL, FEATURES_URL, WRITING_BY_JK_ROWLING, QUIZ_URL } from '../constants/routes';

const lazyLoadedPages = [NEWS_URL, FEATURES_URL, WRITING_BY_JK_ROWLING, QUIZ_URL];

export const scroll = { shouldScrollRestore: false };
let path = '/';

export const saveScrollPos = (url) => {
  const scrollPos = { x: window.scrollX, y: window.scrollY };
  sessionStorage.setItem(url, JSON.stringify(scrollPos));
};

export const restoreScrollPos = (url) => {
  if (lazyLoadedPages.includes(url)) return;
  const scrollPos = JSON.parse(sessionStorage.getItem(url));
  if (scrollPos) {
    window.scrollTo(scrollPos.x, scrollPos.y);
  } else {
    window.scrollTo(0, 0);
  }
};

export const onRouteChangeStart = () => {
  saveScrollPos(path);
};

export const onRouteChangeComplete = (url) => {
  if (scroll.shouldScrollRestore) {
    scroll.shouldScrollRestore = false;
    restoreScrollPos(url);
  } else {
    window.scrollTo(0, 0);
  }
};

export const beforePop = () => {
  scroll.shouldScrollRestore = true;
  return true;
};

export default function useScrollRestoration(router) {
  useEffect(() => {
    if ('scrollRestoration' in window.history) {
      path = router.asPath;

      window.history.scrollRestoration = 'manual';
      restoreScrollPos(path);

      const onBeforeUnload = (event) => {
        saveScrollPos(path);
        // eslint-disable-next-line no-param-reassign
        delete event.returnValue;
      };

      window.addEventListener('beforeunload', onBeforeUnload);
      router.events.on('routeChangeStart', onRouteChangeStart);
      router.events.on('routeChangeComplete', onRouteChangeComplete);
      router.beforePopState(beforePop);

      return () => {
        window.removeEventListener('beforeunload', onBeforeUnload);
        router.events.off('routeChangeStart', onRouteChangeStart);
        router.events.off('routeChangeComplete', onRouteChangeComplete);
        /* istanbul ignore next */
        router.beforePopState(() => true);
      };
    }
    /* istanbul ignore next */
    return () => {};
  }, [router]);
}
