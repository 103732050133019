export const SET_PROGRESS_BAR_LENGTH = 'set length';
export const SET_IS_LOADING_STATE = 'set is loading state';

const LoadersReducer = (state, action) => {
  switch (action.type) {
    case SET_IS_LOADING_STATE:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SET_PROGRESS_BAR_LENGTH:
      return {
        ...state,
        progressBarLength: state.progressBarLength - action.payload,
      };
    default:
      return state;
  }
};

export default LoadersReducer;
