import { useState, useEffect } from 'react';
import { fetchFeatureFlags } from '../../utils/contentfulHelpers';

const useFeatureFlags = () => {
  const [featureFlags, setFeatureFlags] = useState({});
  const [loadingFeatureFlags, setLoadingFeatureFlags] = useState(true);
  const [isMounted, setIsMounted] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const flags = {};
      const fetchedFlags = await fetchFeatureFlags();

      if (fetchedFlags?.flags) {
        fetchedFlags.flags.forEach((flag) => {
          if (flag.body.id) {
            flags[flag.body.id] = flag.body.isToggled;
          }
        });
      }

      if (isMounted) {
        setFeatureFlags(flags);
        setLoadingFeatureFlags(false);
      }
    };
    fetchData();

    return () => {
      setIsMounted(false);
    };
  }, [isMounted]);

  return {
    featureFlags,
    loadingFeatureFlags,
  };
};

export default useFeatureFlags;
