/* eslint-disable dot-notation */
import { useEffect } from 'react';
import SharedLib from 'wizarding-world-web-shared';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import {
  segmentGetTraits,
  segmentUpdateTraits,
  segmentClearTraits,
  segmentResetAnalytics,
  segmentIdentify,
} from '../../utils/analytics';
import { useUserContext } from '../../contexts/user-context';
import {
  RESET_ANALYTICS,
  IS_REGISTERED_FLAG,
  IS_UNDERAGE_FLAG,
  DO_NOT_SELL_OR_SHARE_FLAG,
  DO_NOT_SELL_OR_SHARE_EVENT,
} from '../../constants';

const SegmentIdentify = () => {
  const {
    isLoggedIn,
    loadingProfile,
    loading,
    profile,
    isUserIdentified,
    setIsUserIdentified,
  } = useUserContext();

  const { getCookie, setCookie } = SharedLib.utils.cookie;

  useEffect(() => {
    // istanbul ignore else
    if (!loadingProfile) {
      const registeredFlagIndex = window.dataLayer.findIndex(
        (dl) => dl && IS_REGISTERED_FLAG in dl,
      );
      const underageFlagIndex = window.dataLayer.findIndex((dl) => dl && IS_UNDERAGE_FLAG in dl);
      const doNotSellorShareFlagIndex = window.dataLayer.findIndex(
        (dl) => dl && DO_NOT_SELL_OR_SHARE_FLAG in dl,
      );

      // Manage registered flag in dataLayer
      if (registeredFlagIndex !== -1) {
        window.dataLayer[registeredFlagIndex][IS_REGISTERED_FLAG] = isLoggedIn;
      } else {
        window.dataLayer.push({ [IS_REGISTERED_FLAG]: isLoggedIn });
      }

      // Manage underage flag in dataLayer
      if (isLoggedIn) {
        if (underageFlagIndex !== -1) {
          window.dataLayer[underageFlagIndex][IS_UNDERAGE_FLAG] = profile.underage;
        } else {
          window.dataLayer.push({ [IS_UNDERAGE_FLAG]: profile.underage });
        }
      } else {
        delete window.dataLayer[underageFlagIndex];
      }

      // Delete stale DNSoS events and refire on each profile load
      if (doNotSellorShareFlagIndex !== -1) {
        delete window.dataLayer[doNotSellorShareFlagIndex];
      }
      window.dataLayer.push({
        event: DO_NOT_SELL_OR_SHARE_EVENT,
        [DO_NOT_SELL_OR_SHARE_FLAG]: isLoggedIn && profile?.privacy?.doNotSellOrShare,
      });
    }
  }, [loadingProfile]);

  useEffect(() => {
    if (
      (process.browser || global.isTestRuning) &&
      !loadingProfile &&
      !loading &&
      !isUserIdentified
    ) {
      if (isLoggedIn && !isEmpty(profile)) {
        const currentTraits = segmentGetTraits();
        if (currentTraits?.['last_login']) {
          // remove, newTraits will never have last_login for isEqual comparison
          delete currentTraits.last_login;
        }
        const newTraits = {
          is_underage: profile.underage,
          subscription_type: profile.subscription || 'Free',
        };
        segmentIdentify(profile.id, newTraits);
        if (!isEqual(currentTraits, newTraits)) {
          segmentUpdateTraits(newTraits);
        }
        setIsUserIdentified(true);
      }
      if (!isLoggedIn) {
        // will get resetAnalytics true from cookie when redirect from logout
        const resetAnalytics = getCookie(RESET_ANALYTICS);
        if (resetAnalytics === 'true') {
          setCookie({
            name: RESET_ANALYTICS,
            value: false,
            expires: 600000,
          });
          segmentClearTraits();
          segmentResetAnalytics();
        }
        const currentTraits = segmentGetTraits();
        segmentIdentify(null, currentTraits);
        setIsUserIdentified(true);
      }
    }
  });

  return null;
};
export default SegmentIdentify;
