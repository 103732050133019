import React, { createContext, useContext, useState } from 'react';
import useProfile from '../hooks/useProfile';
import useLoginCheck from '../hooks/useLoginCheck';

export const UserContext = createContext();

export const useUserContext = () => useContext(UserContext);

// eslint-disable-next-line react/prop-types
const UserContextProvider = ({ children }) => {
  const userInfo = useProfile(useLoginCheck());
  const [isUserIdentified, setIsUserIdentified] = useState(false);
  return (
    <UserContext.Provider value={{ isUserIdentified, setIsUserIdentified, ...userInfo }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
