const hasTag = (content, targetTagName) => {
  if (!content?.tags || content?.tags?.length === 0) {
    return false;
  }

  return content?.tags?.some((tag) => {
    return tag.name === targetTagName;
  });
};

export default hasTag;
