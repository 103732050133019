const localizationEnabled = process.env.REACT_APP_LOCALIZATION_PHASE_1_ENABLED === 'TRUE';
const supportedLocales = process.env.REACT_APP_SUPPORTED_LOCALES
  ? process.env.REACT_APP_SUPPORTED_LOCALES.split(',')
  : ['en'];

if (
  !supportedLocales ||
  supportedLocales.length === 0 ||
  !supportedLocales.every((locale) => locale.length === 2)
) {
  throw new Error(
    `Supported locales configured improperly: ${process.env.REACT_APP_SUPPORTED_LOCALES}. Supported locales must be a comma-separated list of 2-character locale codes.`,
  );
}

module.exports = {
  locales: localizationEnabled ? supportedLocales : ['en'],
  countryLocales: {
    // used to map supported locales to country locales required by backend
    en: ['en-GB', 'en-US'],
    ja: ['ja-JP'],
    es: ['es-ES'],
    fr: ['fr-FR'],
    de: ['de-DE'],
  },
  defaultLocale: 'en',
  localeDetection: true,
  pages: {
    '*': ['common'],
    'rgx:^/register.*$': ['auth', 'register', 'newsletter-opt-in'],
    'rgx:^/login.*$': ['auth', 'login'],
    'rgx:^/house-results/.*$': ['house-results'],
    'rgx:^/parental-consent.*$': ['auth', 'register'],
    'rgx:^/grant-permission.*$': ['auth', 'register'],
    'rgx:^/sorting-hat.*$': ['sorting-intro'],
    'rgx:^/forgot-password.*$': ['auth', 'register'],
    'rgx:^/migrate.*$': ['auth', 'register'],
    'rgx:^/profile.*$': ['house-results'],
  },
  loadLocaleFrom:
    // istanbul ignore next
    async (locale, namespace) => import(`./locales/${locale}/${namespace}`).then((r) => r.default),
};
