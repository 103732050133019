import SharedLib from 'wizarding-world-web-shared';
import { UTM_CAMPAIGN_COOKIE } from '../constants';

export const saveUTMParams = (queryParams) => {
  const { setCookie } = SharedLib.utils.cookie;
  if (queryParams) {
    Object.keys(queryParams).forEach((paramName) => {
      if (paramName.startsWith('utm_')) {
        setCookie({
          name: paramName,
          value: queryParams[paramName],
          // ~6 months, the default utm campaign expiration values
          expires: 180 * 24 * 60 * 60 * 1000,
        });
      }
    });
  }
};

export const getUTMCampaign = () => {
  const { getCookie } = SharedLib.utils.cookie;
  const campaign = {
    name: getCookie(UTM_CAMPAIGN_COOKIE) || getCookie('utm_name'),
    source: getCookie('utm_source'),
    medium: getCookie('utm_medium'),
    term: getCookie('utm_term'),
    content: getCookie('utm_content'),
  };

  Object.keys(campaign).forEach((prop) => {
    if (!campaign[prop]) {
      delete campaign[prop];
    }
  });
  return campaign;
};
