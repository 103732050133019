import { CURRENCY_SYMBOLS } from '../constants';

export const romanize = (num) => {
  if (!num) {
    return '';
  }
  const lookup = {
    M: 1000,
    CM: 900,
    D: 500,
    CD: 400,
    C: 100,
    XC: 90,
    L: 50,
    XL: 40,
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1,
  };
  let number = num;
  let roman = '';
  Object.keys(lookup).forEach((key) => {
    while (number >= lookup[key]) {
      roman += key;
      number -= lookup[key];
    }
  });
  return roman;
};

export const analyticsTags = (tags) =>
  (tags || [])
    .map((tag) => {
      if (tag && tag.name) {
        return tag.name;
      }
      return null;
    })
    .filter(Boolean)
    .join(', ');

export const getPathArray = (path) => {
  let newPath = path || '';
  if (newPath.indexOf('?') !== -1) {
    newPath = newPath.substring(0, newPath.indexOf('?'));
  }

  if (newPath.indexOf('#') !== -1) {
    newPath = newPath.substring(0, newPath.indexOf('#'));
  }
  return newPath.split('/');
};

const invalidCharacters = /[\d\u0021-\u0026\u0028-\u002C\u002E-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\u00A1-\u00BF\u00D7\u00F7\u4E00-\u9FCC\u3400-\u4DB5\uFA0E\uFA0F\uFA11\uFA13\uFA14\uFA1F\uFA21\uFA23\uFA24\uFA27-\uFA29]|[\ud840-\ud868][\udc00-\udfff]|\ud869[\udc00-\uded6\udf00-\udfff]|[\ud86a-\ud86c][\udc00-\udfff]|\ud86d[\udc00-\udf34\udf40-\udfff]|\ud86e[\udc00-\udc1d]/;
const hasForbiddenCharacters = (text) => invalidCharacters.test(text);
const hasEmojis = (text) =>
  /([\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/.test(
    text,
  );

export const areSameEmail = (email1 = '', email2 = '') =>
  email1.toLowerCase() === email2.toLowerCase();

export const isValidName = (name) => {
  return name?.trim()?.length > 0 && !hasEmojis(name) && !hasForbiddenCharacters(name);
};

/* istanbul ignore next */
export const isValidEmail = (email) =>
  email.substring(email.lastIndexOf('@') + 1).length > 3 &&
  email.substring(email.lastIndexOf('@') + 1).length <= 255 &&
  email.length < 66 &&
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(email);

export const passwordPolicyCheck = (password) => ({
  minimumLength: password.length >= 8,
  oneNumber: RegExp(/\d/g).test(password),
  oneUppercaseLetter: RegExp(/[A-Z]/g).test(password),
  oneLowercaseLetter: RegExp(/[a-z]/g).test(password),
});

export const allTrue = (obj) => {
  let isAllTrue = true;
  Object.keys(obj).forEach((key) => {
    if (obj[key] === false) isAllTrue = false;
    return isAllTrue;
  });
  return isAllTrue;
};

export const isCardDetailsExists = (paymentDetails) =>
  Boolean(
    paymentDetails.bin !== null && paymentDetails.expiry !== null && paymentDetails.lastDigits,
  );

export const getCurrencySymbol = (code) => CURRENCY_SYMBOLS[code];

export const splitStringByPascalCase = (text) => {
  const wordArray = text.match(/[A-Z][a-z]+/g);
  return wordArray ? wordArray.join(' ') : text;
};

export const shortenString = (inputString, maxLength, separators = ' ', addEllipsis = false) => {
  if (!inputString) return '';
  if (inputString.length <= maxLength) return inputString;

  let endIndex = 0;
  [...separators].forEach((separator) => {
    const separatorIndex = inputString.lastIndexOf(separator, maxLength);
    if (separatorIndex > endIndex) endIndex = separatorIndex;
  });

  return inputString.substr(0, endIndex || maxLength) + (addEllipsis ? '...' : '');
};

export const removeFileExtension = (fileName) => {
  return fileName.substring(0, fileName.lastIndexOf('.'));
};

export const insertIntoString = (fullString, insertString, position) => {
  return `${fullString?.slice(0, position)}${insertString}${fullString?.slice(position)}`;
};

export const capitalize = (string) => {
  return string ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase() : '';
};

export const titleCase = (string) => {
  return string
    ? string
        .toLowerCase()
        .split(' ')
        .map(function(word) {
          return capitalize(word);
        })
        .join(' ')
    : '';
};

export const truncate = (string, length = 30) => {
  return string.length <= length ? string : `${string.substr(0, length - 3)}\u2026`;
};

// stripping headers and links from markdown
export const stripMarkdown = (text) => {
  if (!text) return null;
  const withoutHeaders = text.replace(/#+.*\n/g, '');
  const withoutLinksOrHeaders = withoutHeaders.replace(/(?:__|[*#])|\[(.*?)\]\(.*?\)/gm, '$1');
  return withoutLinksOrHeaders;
};

export const alphabetizeByField = (array, fieldToCompare) => {
  return array.sort((a, b) => {
    const titleA = a[fieldToCompare]?.toLowerCase();
    const titleB = b[fieldToCompare]?.toLowerCase();
    if (titleA < titleB) {
      return -1;
    }
    if (titleA > titleB) {
      return 1;
    }
    return 0;
  });
};
