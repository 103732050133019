import { useEffect, useState } from 'react';
import SharedLib from 'wizarding-world-web-shared';

const useLoginCheck = () => {
  const Auth = SharedLib.services.auth;
  const [loading, setLoading] = useState(true);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [refreshLoggedIn, setRefreshLoggedIn] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userResponse = await Auth.currentUserInfo();
        if (!userResponse) {
          setLoggedIn(false);
          setLoading(false);
        } else {
          setLoggedIn(true);
          setLoading(false);
          setUser(userResponse);
        }
      } catch (e) {
        setLoggedIn(false);
        setLoading(false);
      }
      setRefreshLoggedIn(false);
    };
    fetchData();
  }, [Auth, refreshLoggedIn]);

  return {
    loading,
    isLoggedIn,
    setRefreshLoggedIn,
    user,
  };
};

export default useLoginCheck;
