import MarkdownIt from 'markdown-it';

const md = new MarkdownIt({
  html: true,
  linkify: false,
});

export const renderContentMarkdown = (content) => {
  delete md.renderer.rules.link_open;
  return content.map((data) => {
    const updated = data;

    const sections = updated.body.section.map((section) => {
      const updatedSection = section;
      if (updatedSection.text) {
        updatedSection.text = md.render(section.text);
      }
      return updatedSection;
    });

    updated.section = sections;
    return updated;
  });
};

export const renderPlans = (plans) => {
  md.renderer.rules.link_open = (tokens, idx, options, env, self) => {
    tokens[idx].attrPush(['target', '_blank']);
    return self.renderToken(tokens, idx, options);
  };

  return plans.map((plan) => {
    const {
      externalId,
      planName,
      benefitsImage,
      benefitsList,
      benefitsLegalNote,
      marketingOptInLanguage: { text: marketingOptInLanguageText },
      signUpTermsOfMembership,
      paymentDetailsPurchaseTerms,
      paymentDetailsTermsAndConditions,
    } = plan.body;

    return {
      externalId,
      planName,
      benefitsImage: benefitsImage ?? {},
      benefitsList: md.render(benefitsList),
      benefitsLegalNote: md.render(benefitsLegalNote),
      marketingOptInLanguage: md.render(marketingOptInLanguageText),
      signUpTermsOfMembership: md.render(signUpTermsOfMembership),
      paymentDetailsPurchaseTerms: md.render(paymentDetailsPurchaseTerms),
      paymentDetailsTermsAndConditions: md.render(paymentDetailsTermsAndConditions),
    };
  });
};

export default {
  renderContentMarkdown,
  renderPlans,
};
