import React, { createContext, useContext } from 'react';
import useFeatureFlags from '../hooks/useFeatureFlags';

export const FeatureFlagContext = createContext();

export const useFeatureFlagContext = () => useContext(FeatureFlagContext);

// eslint-disable-next-line react/prop-types
const UseFeatureFlagsProvider = ({ children }) => {
  const featureFlags = useFeatureFlags();

  return (
    <FeatureFlagContext.Provider value={{ ...featureFlags }}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

export default UseFeatureFlagsProvider;
