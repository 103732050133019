import { differenceInYears, format, isBefore, parseISO, parse } from 'date-fns';

export const updateContentDates = (content, field, datePattern) => {
  return content.map((data) => {
    const updatedData = data;
    updatedData.body[field] = format(parseISO(data.body[field]), datePattern);
    return updatedData;
  });
};

export const isInDateFormat = (dob) => {
  return dob.match(/^\d{2}\/\d{2}\/\d{4}$/);
};

// takes in 25/05/1995 and returns 05/25/1995
export const standardizeDate = (dob, dateFormat) => {
  if (!dateFormat.startsWith('dd')) {
    return dob;
  }

  const splitDate = dob.split('/');
  return `${splitDate[1]}/${splitDate[0]}/${splitDate[2]}`;
};

export const isStandardizedDateValid = (date) => {
  const splitDate = date.split('/');
  const [month, day, year] = splitDate;
  const dateObj = new Date(year, month - 1, day);

  if (
    dateObj.getFullYear() === Number(year) &&
    dateObj.getMonth() + 1 === Number(month) &&
    dateObj.getDate() === Number(day) &&
    isBefore(dateObj, new Date())
  ) {
    return true;
  }

  return false;
};

export const getAgeFromBirthdate = (birthdate, dateFormat = 'MM/dd/yyyy') => {
  return differenceInYears(new Date(), parse(birthdate, dateFormat, new Date()));
};

export const getDateFormat = (countryCode) => {
  if (countryCode === 'US' || countryCode === 'CA') {
    return 'MM/dd/yyyy';
  }
  return 'dd/MM/yyyy';
};

export const getPickerDateFormatByCountryCode = (countryCode) => {
  const dateFormatMapping = {
    'MM/dd/yyyy': ['US', 'CA'],
    'yyyy/MM/dd': ['JP', 'KR', 'CN', 'VN', 'TW', 'HU', 'LT'],
  };

  const foundFormat = Object.entries(dateFormatMapping).find(([, countries]) =>
    countries.includes(countryCode),
  );

  // default to 'dd/MM/yyyy' for all other countries
  return foundFormat ? foundFormat[0] : 'dd/MM/yyyy';
};

export const standardizedDateToISO = (date) => {
  const [month, day, year] = date.split('/');
  return `${year}-${month}-${day}`;
};

/**
 * @param {String} dmyDate DD/MM/YYYY
 * @return {String} MM/DD/YYYY
 */
export const dmyToMdY = (dmyDate) => {
  const dateArray = dmyDate.split('/');
  const mdyDate = `${dateArray[1]}/${dateArray[0]}/${dateArray[2]}`;
  return mdyDate;
};

/**
 * @param {String} date
 * @param {String} dateFormat
 * @return {Date} DD
 */
export const getDate = (date, dateFormat) => {
  let mdYDate = date;
  if (dateFormat === 'DD/MM/YYYY') {
    mdYDate = dmyToMdY(date);
  }
  const newDate = new Date(mdYDate);
  return newDate;
};

export const getLocale = async (language) => {
  const dateLocales = await import('date-fns/locale');
  return dateLocales[language];
};

export default {
  updateContentDates,
  isInDateFormat,
  standardizeDate,
  isStandardizedDateValid,
  getAgeFromBirthdate,
  getDateFormat,
  getPickerDateFormatByCountryCode,
  standardizedDateToISO,
  getLocale,
};
