export const hubList = [
  { tag: 'news', hub: 'news', pageName: 'News Hub', displayName: 'News' },
  { tag: 'feature', hub: 'features', pageName: 'Features Hub', displayName: 'Feature' },
  { tag: 'quiz', hub: 'quiz', pageName: 'Quiz Hub', displayName: 'Quiz' },
  {
    tag: 'writing-by-jk-rowling',
    hub: 'writing-by-jk-rowling',
    pageName: 'JK Rowling Hub',
    displayName: 'JK Rowling Original',
  },
];
const findHub = (tagInfo) => {
  const tagsArray = typeof tagInfo === 'string' ? [{ name: tagInfo }] : tagInfo;
  let foundHub = { tag: 'feature', hub: 'features' };
  tagsArray.forEach((tag) => {
    hubList.forEach((hubObject) => {
      if (hubObject.tag === tag.name) {
        foundHub = hubObject;
      }
    });
  });
  return foundHub;
};
export const findPageName = (hubName) => {
  const [currentHub] = hubList.filter((hub) => hub.hub === hubName.toLowerCase());
  if (currentHub) {
    return currentHub.pageName;
  }
  return '';
};

export const findHubName = (tagInfo) => findHub(tagInfo).hub;
export const findTagName = (tagInfo) => findHub(tagInfo).tag;
export const findDisplayName = (tagInfo) => findHub(tagInfo).displayName;

export const findUrl = (tags, externalId) => {
  return `/${findHubName(tags)}/${externalId}`;
};

export default {
  hubList,
  findHubName,
  findTagName,
  findDisplayName,
  findPageName,
  findUrl,
};
