import React, { useState, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import Loaders from '../Loaders';
import loaderTypes from '../Loaders/loaderTypes';
import useScrollRestoration from '../../hooks/useScrollRestoration';
import { setIsLoadingPage } from '../../globalStates/RouteLoadingState';
import { NO_LOADER_ROUTES, PORTRAIT_MAKER } from '../../constants/routes';

const RouterLoader = () => {
  const [shouldShowLoader, setShouldShowLoader] = useState(false);
  const [loaderType, setLoaderType] = useState(loaderTypes.timeturner);
  const router = useRouter();
  const isFirstRun = useRef(true);
  const counterTimeout = useRef(null);
  useScrollRestoration(router);

  const shouldIgnoreUrl = (url) => {
    return NO_LOADER_ROUTES.find((search) => url.includes(search));
  };

  const handleLoaderTypes = (url) => {
    if (url === PORTRAIT_MAKER) {
      setLoaderType(loaderTypes.paintbrush);
    } else {
      setLoaderType(loaderTypes.timeturner);
    }
  };

  useEffect(() => {
    const routeChangeCompleteHandler = () => {
      clearTimeout(counterTimeout.current);
      setIsLoadingPage(false);
      setShouldShowLoader(false);
    };

    const routeChangeStartHandler = (url) => {
      // prevents race condition that occurs if counterTimeout
      // fires in between route changes.
      clearTimeout(counterTimeout.current);

      handleLoaderTypes(url);

      // Only show the loader if it's loading for more than 150ms.
      // This prevents the unpleasant flashing of the loader when a page loads from cache,
      // and thus is not necessary. This timeout number was picked because it's small
      // enough not to notice, but large enough to prevent flashing.
      setIsLoadingPage(true);
      counterTimeout.current = setTimeout(() => {
        if (!shouldIgnoreUrl(url)) {
          setShouldShowLoader(true);
        }
      }, 150);
    };

    router.events.on('routeChangeComplete', routeChangeCompleteHandler);
    router.events.on('routeChangeError', routeChangeCompleteHandler);
    router.events.on('routeChangeStart', routeChangeStartHandler);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeComplete', routeChangeCompleteHandler);
      router.events.off('routeChangeError', routeChangeCompleteHandler);
      router.events.off('routeChangeStart', routeChangeStartHandler);
    };
  });

  useEffect(() => {
    if (shouldShowLoader === false && !isFirstRun.current) {
      setTimeout(() => {
        // eslint-disable-next-line no-self-assign
        if (window.location.hash) window.location = window.location;
      }, 200);
    }
  }, [shouldShowLoader]);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
    }
  });

  return shouldShowLoader && <Loaders type={loaderType} />;
};

export default RouterLoader;
