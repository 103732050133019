import { useState, useEffect } from 'react';
import SharedLibs from 'wizarding-world-web-shared';
import { PROFILE_QUERY } from '../../utils/graphQueries';

const useProfile = ({ isLoggedIn, ...props }) => {
  const [profile, setProfile] = useState({});
  const [shouldRefreshProfile, setShouldRefreshProfile] = useState(false);
  const [loadingProfile, setLoadingProfile] = useState(true);
  const [isMounted, setIsMounted] = useState(true);

  const updateProfile = async () => {
    const { api: wizardingWorldApi } = SharedLibs.services;
    const { data } = await wizardingWorldApi().query({
      query: PROFILE_QUERY,
    });
    if (data.me) {
      setProfile(data.me);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const emptyProfile = profile && Object.keys(profile).length === 0;

      if (isMounted && emptyProfile) {
        setLoadingProfile(true);
        try {
          await updateProfile();
          setLoadingProfile(false);
        } catch {
          setLoadingProfile(false);
        }
      }
    };
    fetchData();

    return () => {
      setIsMounted(false);
    };
  }, [profile, isLoggedIn, isMounted]);

  return {
    loadingProfile,
    isLoggedIn,
    shouldRefreshProfile,
    setShouldRefreshProfile,
    profile,
    updateProfile,
    ...props,
  };
};

export default useProfile;
