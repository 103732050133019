/* eslint-disable consistent-return */
import SharedLib from 'wizarding-world-web-shared';
import { NEXT_LOCALE_COOKIE } from '@constants/localization';
import { analyticsTags } from '@utils/index';
import { getUTMCampaign } from './utm';
import { locales as SUPPORTED_LOCALES } from '../../i18n';

export const initSegmentQueue = () => {
  // This is a copy of the first part of the segment snippet here:
  // https://segment.com/docs/sources/website/analytics.js/quickstart/
  // It creates a queue to hold all segment events until analytics.js is loaded (async from gtm)
  // Create a queue, but don't obliterate an existing one!
  // eslint-disable-next-line no-multi-assign
  const analytics = (window.analytics = window.analytics || []);

  // If the real analytics.js is already on the page return.
  if (analytics.initialize) return;

  // A list of the methods in Analytics.js to stub.
  analytics.methods = [
    'trackSubmit',
    'trackClick',
    'trackLink',
    'trackForm',
    'pageview',
    'identify',
    'reset',
    'group',
    'track',
    'ready',
    'alias',
    'debug',
    'page',
    'once',
    'off',
    'on',
    'user',
  ];

  // Define a factory to create stubs. These are placeholders
  // for methods in Analytics.js so that you never have to wait
  // for it to load to actually record data. The `method` is
  // stored as the first argument, so we can replay the data.
  analytics.factory = (method) => (...args) => {
    args.unshift(method);
    analytics.push(args);
    return analytics;
  };

  // For each of our methods, generate a queueing stub.
  for (let i = 0; i < analytics.methods.length; i += 1) {
    const key = analytics.methods[i];
    analytics[key] = analytics.factory(key);
  }
};

export const integrations = {
  'Facebook Pixel': false,
  'Google AdWords New': false,
};

export const toggleAdIntegration = () => {
  const traits = window.analytics.user().traits();
  if (
    traits &&
    (traits.is_underage === false || (traits.is_underage !== true && traits.retargeting === true))
  ) {
    integrations['Facebook Pixel'] = true;
    integrations['Google AdWords New'] = true;
  }
};

export const stripLocaleFromURL = (inputUrl) => {
  try {
    const [base, query] = inputUrl?.split('?');
    const shortPathLocaleRegex = new RegExp(`^/(${SUPPORTED_LOCALES.join('|')})$`, 'gi'); // /en -> /
    const fullUrlLocaleRegex = new RegExp(`/(${SUPPORTED_LOCALES.join('|')})/`, 'gi'); // xyz.com/en/feature -> xyz.com/feature
    const shortUrlLocaleRegex = new RegExp(`/(${SUPPORTED_LOCALES.join('|')})$`, 'gi'); // xyz.com/en -> xyz.com

    const newBase = base
      ?.replace(shortPathLocaleRegex, '/')
      ?.replace(fullUrlLocaleRegex, '/')
      ?.replace(shortUrlLocaleRegex, '');
    const newQuery = query ? `?${query}` : '';
    return `${newBase}${newQuery}`;
  } catch {
    return inputUrl;
  }
};

export const getLanguage = () => {
  const { getCookie } = SharedLib.utils.cookie;
  return getCookie(NEXT_LOCALE_COOKIE) || 'en';
};

let gaClientId = null;

export const segmentIdentify = (userId, traits) => {
  return new Promise((resolve) => {
    const campaign = getUTMCampaign();
    const date = new Date().toISOString();
    const getClientId = (tracker) => {
      gaClientId = tracker.get('clientId');
      window.analytics.identify(
        userId,
        {
          ...traits,
          last_login: date,
          gid: gaClientId,
          language: getLanguage(),
        },
        {
          campaign,
          page: {
            // strip any locale (/en/, /ja/, etc.) from url context properties to normalize data
            url: stripLocaleFromURL(window.location.href),
            path: stripLocaleFromURL(window.location.pathname),
            referrer: stripLocaleFromURL(document.referrer),
          },
        },
      );
      resolve();
    };

    // Don't wait around for promise resolution if analytics isn't
    // ready yet; window.analytics.ready can block indefinitely.
    /* eslint-disable-next-line no-underscore-dangle */
    if (window.analytics._readied) {
      window.ga(getClientId);
    } else {
      window.analytics.ready(() => {
        window.ga(getClientId);
      });
      resolve();
    }
  });
};

export const segmentPage = (pageName, properties, context = {}) => {
  const callback = () => {
    const campaign = getUTMCampaign();
    const traits = {
      ...window.analytics.user().traits(),
      ...context,
    };
    // strip any locale (/en/, /ja/, etc.) from url properties to normalize data
    const modifiedProperties = {
      ...properties,
      language: getLanguage(),
      url: stripLocaleFromURL(properties?.url || window.location.href),
      path: stripLocaleFromURL(properties?.path || window.location.pathname),
      referrer: stripLocaleFromURL(properties?.referrer || document.referrer),
    };

    toggleAdIntegration();
    window.analytics.page(pageName, modifiedProperties, { integrations, traits, campaign });
  };

  window.analytics.ready(callback);
  return callback;
};

export const trackEvent = (event, properties, context = {}) => {
  const callback = () => {
    const campaign = getUTMCampaign();
    const traits = {
      ...window.analytics.user().traits(),
      ...context,
    };
    const modifiedProperties = {
      ...properties,
      language: getLanguage(),
    };

    if (properties && properties.cta_text && !properties.label) {
      modifiedProperties.label = properties.cta_text;
    }

    toggleAdIntegration();
    window.analytics.track(event, modifiedProperties, {
      traits,
      integrations,
      campaign,
      page: {
        // strip any locale (/en/, /ja/, etc.) from url context properties to normalize data
        url: stripLocaleFromURL(modifiedProperties?.url || window.location.href),
        path: stripLocaleFromURL(modifiedProperties?.path || window.location.pathname),
        referrer: stripLocaleFromURL(modifiedProperties?.referrer || document.referrer),
      },
    });
  };

  window.analytics.ready(callback);
  return callback;
};

export const trackClickEvent = (analyticsParams, clickedContent = {}) => {
  const params = {
    source_url: window.location.pathname,
    ...analyticsParams,
  };

  // Default to certain fields on the clicked content if not explicitly set in analyticsParams
  const contentId = analyticsParams.content_id || clickedContent?.contentfulId;
  const contentType = analyticsParams.content_type || clickedContent?.contentTypeId;
  const contentTags = analyticsParams.content_tags || analyticsTags(clickedContent?.tags);
  const contentName = analyticsParams.content_name?.trim() || clickedContent?.entryTitle?.trim();

  // Only include these optional params if they are defined. Do not fall back to
  // an empty or null value.
  if (contentId) params.content_id = contentId;
  if (contentType) params.content_type = contentType;
  if (contentTags) params.content_tags = contentTags;
  if (contentName) params.content_name = contentName;

  trackEvent('Click', params);
};

export const segmentClearTraits = () => {
  const callback = () => {
    window.analytics.user().traits({});
  };
  window.analytics.ready(callback);
  return callback;
};

export const segmentResetAnalytics = () => window.analytics.reset();

export const segmentAnonAlias = (userId, prevId) => {
  const callback = () => {
    window.analytics.alias(userId, { prevId, integrations });
  };
  window.analytics.ready(callback);
  return callback;
};

export const segmentUpdateTraits = (traits) => {
  const callback = () => {
    const userId = window.analytics.user().id();
    const date = new Date().toISOString();
    window.analytics.identify(userId, { ...traits, last_login: date });
    toggleAdIntegration();
  };
  window.analytics.ready(callback);
  return callback;
};

export const segmentAnonymousId = () => {
  if (window.analytics && window.analytics.user && window.analytics.user().anonymousId) {
    return window.analytics.user().anonymousId();
  }
  return null;
};

export const segmentGetTraits = () => {
  if (window.analytics && window.analytics.initialize) {
    return window.analytics.user().traits();
  }

  // fallback
  return JSON.parse(localStorage.getItem('ajs_user_traits'));
};

export const segmentUserId = () => {
  if (window.analytics && window.analytics.initialize) {
    return window.analytics.user().id();
  }

  // fallback
  return JSON.parse(localStorage.getItem('ajs_user_id'));
};
